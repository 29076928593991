import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../utils/IP'; 

import { Container, Box, Typography, IconButton, TextField, Button, Paper, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate , useLocation} from 'react-router-dom';
import { PropertyContext } from '../../contexts/PropertyContext';
import { useDueContext} from '../../contexts/DueContext';
import axios from 'axios';
import { fetchDues } from '../../features/propertySlice';
import colors from '../../styles/colors';

const dueTypes = [
  { label: 'Rent', value: 'Rent' },
  { label: 'Electricity Bill', value: 'electricity' },
  { label: 'Water Bill', value: 'water' },
  { label: 'Maintenance', value: 'maintenance' },
  

  // Add more due types as needed
];

const AddDue = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { tenantId, roomId } = location.state || {};
  const { properties, selectedProperty, loading, tenants, rooms, dues, complaints } = useSelector(state => state.property);
  
  const [dueData, setDueData] = useState({
    dueAmount: '',
    totalDue: '', // New field for total due
    paymentAmount: 0 , // New field for total due
    dueType: '',
    dueDate: '',
    remark: '',
    tenant: tenantId || '',
    room: roomId || '',
    property: selectedProperty ? selectedProperty.name : '',
    status: 'Pending',
  });

  const [tenantName, setTenantName] = useState('');
  const [roomName, setRoomName] = useState('');

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous screen
  };

  const handleChange = (e) => {
    setDueData({ ...dueData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (tenantId && tenants.length > 0) {
      const tenant = tenants.find(t => t.tenant._id === tenantId);
      console.log('this the the tenantID',tenant)

      if (tenant) {
        setTenantName(tenant.tenant.name);
      }
    }

    if (roomId && rooms.length > 0) {
      const room = rooms.find(r => r._id === roomId);
      if (room) {
        setRoomName(room.name);
      }
    }
  }, [tenantId, roomId, tenants, rooms]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      const isRent = dueData.dueType === 'Rent';

      const payload = {
        dueAmount: dueData.dueAmount,
        totalDue: dueData.dueAmount, // Set totalDue equal to the initial dueAmount
        paymentAmount: dueData.paymentAmount, // Set totalDue equal to the initial dueAmount
        dueType: dueData.dueType,
        dueDate: dueData.dueDate,
        remark: dueData.remark,
        tenant: dueData.tenant,
        room: dueData.room,
        status: dueData.status,
        isRent
      };
      await axios.post(`${BASE_URL}/dues`, payload, config);
      
   /*   if (selectedProperty) {
        dispatch(fetchDues(selectedProperty._id));
      }
    */
      navigate('/tenants');
    } catch (error) {
      console.error('Error adding due:', error);
    }
  };

  const textFieldStyle = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.primary,
      },
      '&:hover fieldset': {
        borderColor: 'grey',
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.primary,
      },
      '&.Mui-disabled fieldset': {
        borderColor: colors.primary,
      },
    },
    '& .MuiOutlinedInput-input': {
      color: colors.primary,
      '&.Mui-disabled': {
        color: colors.primary,
        WebkitTextFillColor: colors.primary,
        opacity: 1,
      },
    },
    '& .MuiInputLabel-outlined': {
      color: 'grey',
      '&.Mui-focused': {
        color: 'grey',
      },
    },
  };

  return (
    <Container maxWidth="sm" disableGutters>
      <Box
        sx={{
          bgcolor: colors.secondary, // Primary color
          pb: 2,
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
          mx: 0,
          px: 2,
          color: colors.primary,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: 2 }}>
          <IconButton onClick={handleBackClick} sx={{ color: colors.primary }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ fontSize: '1.2rem', textAlign: 'left', flexGrow: 1 }}>Add Due</Typography>
          <Box sx={{ width: 24 }} /> {/* Placeholder for spacing */}
        </Box>
      </Box>

      <Paper sx={{ mt: 2, p: 2 }}>
        <Box sx={{ mt: 3 ,pl:2,pr:2,}}>
            <TextField
              fullWidth
              label="Property Name"
              variant="outlined"
              sx={{ mb: 2, ...textFieldStyle }}
              value={dueData.property}
              disabled
            />
            <TextField
              fullWidth
              label="Tenant Name"
              variant="outlined"
              sx={{ mb: 2, ...textFieldStyle }}
              value={tenantName}
              disabled
            />
            <TextField
              fullWidth
              label="Room Number"
              variant="outlined"
              sx={{ mb: 2, ...textFieldStyle }}
              value={roomName}
              disabled
            />

            <TextField
              select
              fullWidth
              label="Due Type"
              name="dueType"
              value={dueData.dueType}
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
            >
              {dueTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              label="Due Amount"
              variant="outlined"
              sx={{ mb: 2 }}
              name="dueAmount"
              value={dueData.dueAmount}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              label="Due Date"
              variant="outlined"
              type="date"
              sx={{ mb: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
              name="dueDate"
              value={dueData.dueDate}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              label="Remark"
              variant="outlined"
              multiline
              rows={4}
              sx={{ mb: 2 }}
              name="remark"
              value={dueData.remark}
              onChange={handleChange}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ backgroundColor: '#1AA393', textTransform: 'none' }}
              onClick={handleSubmit}
            >
              Add Due
            </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default AddDue;
