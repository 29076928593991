import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import RoomIcon from '@mui/icons-material/Room';
import colors from '../../styles/colors';
import { DoorFrontSharp,CurrencyRupeeTwoTone, EmojiPeopleTwoTone,HomeMaxTwoTone , BalconyTwoTone,Apartment} from '@mui/icons-material';


const BottomNavigationComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState('');

  const navItems = [
    { label: 'Home', value: '/home', icon: <HomeIcon />, paths: ['/', '/home'] },
    { label: 'Money', value: '/rent-due-collection', icon: <CurrencyRupeeIcon /> },
    { label: 'Tenants', value: '/tenants', icon: <EmojiPeopleTwoTone /> },
    { label: 'Rooms', value: '/rooms', icon: <DoorFrontSharp /> },
  ];

  useEffect(() => {
    const currentPath = location.pathname;
    const matchingItem = navItems.find(item => 
      item.paths 
        ? item.paths.some(path => currentPath === path || currentPath.startsWith(path + '/'))
        : currentPath === item.value || currentPath.startsWith(item.value + '/')
    );
    setValue(matchingItem ? matchingItem.value : '');
  }, [location]);

  const handleNavigationChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  return (
    <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000, }}>
      <BottomNavigation
        value={value}
        onChange={handleNavigationChange}
        showLabels
        sx={{
        background: `linear-gradient(110deg, ${colors.secondary} 0%,${colors.secondary} 50%, ${colors.secondary} 100%)`,
        boxShadow: '0px 10px 50px rgba(0,0,0,0.5)',
         // boxShadow: `0 0 30px ${colors.mint}`,
          height: '3.8rem',
          padding: '0.25rem',
          borderTopRightRadius:'1px',
          borderTopLeftRadius:'1px'
        }}
      >
        {navItems.map((item) => (
          <BottomNavigationAction
            key={item.value}
            component={Link}
            to={item.value}
            label={item.label}
            value={item.value}
            icon={item.icon}
            sx={{
              color: colors.primary,
              minWidth: 'auto',
              maxWidth: '25%',
              padding: '0.5rem',
              margin: '0 0.25rem',
              transition: 'all 0.0s ease',
              opacity:0.9,
              '&.Mui-selected': {
                color: colors.primary,
                opacity:1,
                //boxShadow: `0 0 5px `,
                //borderRadius: '15px',
                //backgroundColor: colors.secondary,
              },
              '& .MuiBottomNavigationAction-label': {
                fontSize: '0.75rem',
                transition: 'font-size 0.2s, opacity 0.2s',
                opacity: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
              '&.Mui-selected .MuiBottomNavigationAction-label': {
                fontSize: '0.875rem',
                fontWeight: 'bold',
              },
              '& .MuiSvgIcon-root': {
                fontSize: '1.5rem',
                transition: 'font-size 0.2s',
              },
              '&.Mui-selected .MuiSvgIcon-root': {
                fontSize: '1.75rem',
              },
            }}
          />
        ))}
      </BottomNavigation>
    </Box>
  );
};

export default BottomNavigationComponent;