const colors = {
  primary: '#303030',
  secondary: '#FFF',
  error: '#DA6565',
  warning: '#AE9600',
  info: '#2480A9',
  success: '#4DE5DD',
  background: '#F5F5F5',
  paper: '#FFFFFF',
  textPrimary: '#000000',
  grey: '#333333',
  textSecondary: '#757575',
  teal:'#1AA393',
  darkteal: '#167D7F',
  mint:'#D0E5E5',
  peach:'#FF6B6B',
  gold:'#FFD700',
  lavendar: '#9C89FF',
  neonblue: '#4DE5DD',
  red:'#FFE5E5',
  darkred:'#FF4B4B',
  lightgrey:'#C0C0C0',
  yellow:'#FF9800'
};

export default colors;