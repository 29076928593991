import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Typography,
  InputAdornment,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Badge
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import colors from '../../styles/colors';
import CentralDialog from '../../utils/CentralDialog';
import { deleteExpense } from '../../features/propertySlice';

const ExpensesTab = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { expenses } = useSelector(state => state.property);
  
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [dialogConfig, setDialogConfig] = useState({
    open: false,
    title: '',
    content: '',
    type: '',
    onConfirm: null
  });

  const filteredExpenses = expenses.filter(expense => 
    expense.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
    expense.paidBy.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOptionsClick = (event, expense) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedExpense(expense);
  };

  const handleOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateExpense = () => {
    handleOptionsClose();
    navigate(`/update-expense/${selectedExpense._id}`, { 
      state: { expenseData: selectedExpense } 
    });
  };

  const handleDeleteClick = () => {
    handleOptionsClose();
    setDialogConfig({
      open: true,
      title: 'Confirm Delete',
      content: 'Are you sure you want to delete this expense?',
      type: 'confirm',
      onConfirm: handleDeleteConfirm
    });
  };

  const handleDeleteConfirm = async () => {
    try {
      await dispatch(deleteExpense(selectedExpense._id)).unwrap();
      setDialogConfig({
        open: true,
        title: 'Success',
        content: 'Expense deleted successfully',
        type: 'success'
      });
    } catch (error) {
      setDialogConfig({
        open: true,
        title: 'Error',
        content: 'Failed to delete expense. Please try again.',
        type: 'error'
      });
    }
  };

  const handleCloseDialog = () => {
    setDialogConfig(prev => ({ ...prev, open: false }));
  };

  return (
    <Box>
      <Box
      sx={{mt: 0, 
        mb: 2, 
        display: 'flex', 
        gap: 1.5, 
        alignItems: 'center' // Ensure vertical alignment
        }}>
      <TextField
              fullWidth
              placeholder="Search tenants..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: colors.teal, fontSize: 20 }} />
                  </InputAdornment>
                ),
                sx: { // Style the input directly
                  height: 45, // Match height with icon button
                  padding: '0 12px', // Reduce horizontal padding
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  bgcolor: 'white',
                  borderRadius: 2,
                  '& fieldset': { // Style the outline
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.2)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.teal,
                  }
                },
                '& .MuiInputBase-input': { // Style the input text
                  padding: '8px 0', // Reduce vertical padding
                  fontSize: '0.875rem', // Slightly smaller text
                }
              }}
            />
          </Box>

      {filteredExpenses.map((expense, index) => (
        <Card
          key={index}
          sx={{
            mb: 2,
            p: 2,
            borderRadius: 3,
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            transition: 'transform 0.2s',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{
              width: 48,
              height: 48,
              borderRadius: 2,
              bgcolor: `${colors.primary}15`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '1.25rem',
              fontWeight: 600,
              color: colors.primary,
            }}>
              {expense.category.charAt(0)}
            </Box>

            <Box sx={{ flex: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.primary }}>
                {expense.category}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {new Date(expense.paidDate).toLocaleDateString()}
              </Typography>
            </Box>

            <Box sx={{ textAlign: 'right', mr: 4 }}>
              <Typography variant="subtitle2" sx={{ color: '#FF4B4B', fontWeight: 600 }}>
                ₹{expense.amount.toLocaleString()}
              </Typography>
              <Typography variant="caption" sx={{ color: colors.teal, display: 'block' }}>
                Paid by: {expense.paidBy}
              </Typography>
            </Box>

            <IconButton 
              onClick={(e) => handleOptionsClick(e, expense)}
              sx={{ color: colors.primary }}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        </Card>
      ))}

      {/* Add Expense FAB */}
      <Box
        onClick={() => navigate('/add-expense')}
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 80,
          zIndex: 1000,
          bgcolor: colors.teal,
          color: 'white',
          borderRadius: '16px',
          p: 1.5,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
          cursor: 'pointer',
          transition: 'all 0.2s',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 6px 16px rgba(0,0,0,0.2)',
          },
        }}
      >
        <AddIcon />
        <Typography sx={{ fontWeight: 500 }}>
          Add Expense
        </Typography>
      </Box>

      {/* Options Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleOptionsClose}
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          }
        }}
      >
        <MenuItem onClick={handleUpdateExpense}>Update</MenuItem>
        <MenuItem onClick={handleDeleteClick} sx={{ color: '#FF4B4B' }}>Delete</MenuItem>
      </Menu>

      {/* Dialogs */}
      <CentralDialog
        open={dialogConfig.open}
        onClose={handleCloseDialog}
        title={dialogConfig.title}
        content={dialogConfig.content}
        type={dialogConfig.type}
        onConfirm={dialogConfig.onConfirm}
      />
    </Box>
  );
};

export default ExpensesTab;