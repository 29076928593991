import React, { useState ,useEffect} from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  Paper,
  IconButton,
  InputAdornment,
  CircularProgress,
  Alert,
  Fade,
  Link,
  Stepper,
  Step,
  StepLabel,MenuItem
} from '@mui/material';
import {
  Visibility,
  VisibilityOff,
  Person as PersonIcon,
  Phone as PhoneIcon,
  Lock as LockIcon,
  Email as EmailIcon,
  Business as BusinessIcon
} from '@mui/icons-material';
import { jwtDecode } from 'jwt-decode';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { addProperty } from '../../features/propertySlice';
import { BASE_URL } from '../../utils/IP';
import colors from '../../styles/colors';

const styles = {
  container: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bgcolor: '#f5f7fa',
    p: 2
  },
  paper: {
    width: '100%',
    maxWidth: 500,
    p: 4,
    borderRadius: 3,
    boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
  },
  logo: {
    width: 80,
    height: 80,
    mb: 3,
    mx: 'auto',
    display: 'block'
  },
  title: {
    textAlign: 'center',
    mb: 4,
    color: colors.primary,
    fontWeight: 600
  },
  stepper: {
    mb: 4,
  },
  textField: {
    mb: 2,
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: colors.teal,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.teal,
      }
    }
  },
  button: {
    mt: 3,
    mb: 2,
    height: 48,
    bgcolor: colors.teal,
    '&:hover': {
      bgcolor: colors.darkteal,
    }
  },
  error: {
    mb: 2
  }
};

const HomeownerSignup = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const token = new URLSearchParams(location.search).get('token');

  const [activeStep, setActiveStep] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [invitationValid, setInvitationValid] = useState(false);
  const [invitedEmail, setInvitedEmail] = useState('');


  


  const [formData, setFormData] = useState({
    // User Account Details
    username: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    // Property Details
    propertyName: '',
    propertyType: '',
    address: '',
    city: '',
    state: '',
    pincode: ''
  });

  // In your HomeownerSignup component, update the token verification:

useEffect(() => {
    const verifyInvitation = async () => {
      if (!token) {
        setError('No invitation token found. Please use the invitation link provided.');
        return;
      }
  
      try {
        // Verify token with backend
        const response = await axios.post(`${BASE_URL}/users/verify-invitation`, { token });
        
        if (response.data.valid) {
          setInvitedEmail(response.data.email);
          setFormData(prev => ({ 
            ...prev, 
            email: response.data.email 
          }));
          setInvitationValid(true);
        } else {
          setError('Invalid invitation token.');
        }
      } catch (error) {
        setError(error.response?.data?.message || 'Invalid invitation token.');
      }
    };
  
    verifyInvitation();
  }, []);
  
  // Update handleSubmit to properly handle registration
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep()) return;
  
    setLoading(true);
    try {
      // Register homeowner with invitation token
      const response = await axios.post(`${BASE_URL}/users/register`, {
        token,
        userData: {
          username: formData.username,
          password: formData.password,
          email: formData.email,
          phone: formData.phone
        }
      });
  
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        
        // Create the property using the token
        await dispatch(addProperty({
          name: formData.propertyName,
          type: formData.propertyType,
          address: formData.address,
          city: formData.city,
          state: formData.state,
          pincode: formData.pincode
        })).unwrap();
  
        navigate('/login');
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Registration failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const steps = ['Account Details', 'Property Information'];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError('');
  };

  const validateStep = () => {
    if (activeStep === 0) {
      if (!formData.username || !formData.email || !formData.phone || !formData.password || !formData.confirmPassword) {
        setError('Please fill in all fields');
        return false;
      }
      if (formData.password !== formData.confirmPassword) {
        setError('Passwords do not match');
        return false;
      }
      // Basic email validation
      if (!/\S+@\S+\.\S+/.test(formData.email)) {
        setError('Please enter a valid email address');
        return false;
      }
    } else {
      if (!formData.propertyName || !formData.address || !formData.city || !formData.state || !formData.pincode) {
        setError('Please fill in all property details');
        return false;
      }
    }
    return true;
  };

  const handleNext = () => {
    if (validateStep()) {
      setActiveStep((prevStep) => prevStep + 1);
      setError('');
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
    setError('');
  };


  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <TextField
              fullWidth
              name="username"
              label="Username"
              value={formData.username}
              onChange={handleChange}
              sx={styles.textField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon sx={{ color: colors.teal }} />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              name="email"
              label="Email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              sx={styles.textField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon sx={{ color: colors.teal }} />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              name="phone"
              label="Phone Number"
              value={formData.phone}
              onChange={handleChange}
              sx={styles.textField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon sx={{ color: colors.teal }} />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handleChange}
              sx={styles.textField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: colors.teal }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type={showPassword ? 'text' : 'password'}
              value={formData.confirmPassword}
              onChange={handleChange}
              sx={styles.textField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: colors.teal }} />
                  </InputAdornment>
                ),
              }}
            />
          </>
        );

      case 1:
        return (
          <>
            <TextField
              fullWidth
              name="propertyName"
              label="Property Name"
              value={formData.propertyName}
              onChange={handleChange}
              sx={styles.textField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessIcon sx={{ color: colors.teal }} />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              name="propertyType"
              label="Property Type"
              value={formData.propertyType}
              onChange={handleChange}
              sx={styles.textField}
              select
            >
              <MenuItem value="Apartment">Apartment</MenuItem>
              <MenuItem value="House">House</MenuItem>
              <MenuItem value="PG">PG</MenuItem>
              <MenuItem value="Hostel">Hostel</MenuItem>
            </TextField>

            <TextField
              fullWidth
              name="address"
              label="Address"
              value={formData.address}
              onChange={handleChange}
              multiline
              rows={2}
              sx={styles.textField}
            />

            <TextField
              fullWidth
              name="city"
              label="City"
              value={formData.city}
              onChange={handleChange}
              sx={styles.textField}
            />

            <TextField
              fullWidth
              name="state"
              label="State"
              value={formData.state}
              onChange={handleChange}
              sx={styles.textField}
            />

            <TextField
              fullWidth
              name="pincode"
              label="Pincode"
              value={formData.pincode}
              onChange={handleChange}
              sx={styles.textField}
            />
          </>
        );

      default:
        return null;
    }
  };

  return (
    <Container maxWidth={false} sx={styles.container}>
      <Paper sx={styles.paper}>
      <Box 
          sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        > 
        <Box
             component="img"
              sx={{  width: '150px', marginBottom: '20px',mb:3 }}
              alt="Company Logo"
              src={`${process.env.PUBLIC_URL}/icons/Rizzy_black_logo0.33x.png`}
                    />
        <Typography variant="body3" sx={styles.title}>
        Register as Property Owner
        </Typography>
        

        <Stepper activeStep={activeStep} sx={styles.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Fade in={!!error}>
          <Alert severity="error" sx={styles.error}>
            {error}
          </Alert>
        </Fade>

        <form onSubmit={handleSubmit}>
          {renderStepContent(activeStep)}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Button
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{ ...styles.button, bgcolor: 'grey.300', '&:hover': { bgcolor: 'grey.400' } }}
            >
              Back
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                sx={styles.button}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Complete Registration'}
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={handleNext}
                sx={styles.button}
              >
                Next
              </Button>
            )}
          </Box>
        </form>

        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <Link
            href="/login"
            underline="hover"
            sx={{ color: colors.teal }}
          >
            Already have an account? Login
          </Link>
        </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default HomeownerSignup;