import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  Box, 
  Container, 
  IconButton, 
  Tab, 
  Tabs, 
  Typography,
  Card,
  CardContent,
  Grid,
  Paper,
  Chip,
  Badge,
  LinearProgress
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  ResponsiveContainer,
  Tooltip
} from 'recharts';

import Collections from './CollectionTab';
import DuesTab from './DuesTab';
import ExpensesTab from './ExpensesTab';
import BottomNavigationComponent from '../others/BottomNavigationComponent';
import { 
  selectPaidDues, 
  selectAggregatedPendingDues, 
  selectTotalPendingDue, 
  selectTotalCollection, 
  selectTotalExpense 
} from '../../features/propertySlice';
import colors from '../../styles/colors';

const RentDueCollection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  
  const [tabIndex, setTabIndex] = useState(() => location.state?.activeTab ?? 0);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState('currentMonth');

  const { dues, tenants } = useSelector(state => state.property);
  const paidDues = useSelector(selectPaidDues);
  const aggregatedPendingDues = useSelector(selectAggregatedPendingDues);
  const totalPendingDue = useSelector(selectTotalPendingDue);
  const totalCollection = useSelector(selectTotalCollection);
  const totalExpense = useSelector(selectTotalExpense);
  
  const underVerificationCount = dues.filter(due => due.status === 'UnderVerification').length;

  // Calculate daily collections for the chart
  const dailyCollections = useMemo(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    return paidDues
      .filter(due => {
        const dueDate = new Date(due.paymentDateTime);
        return dueDate.getMonth() === currentMonth && dueDate.getFullYear() === currentYear;
      })
      .reduce((acc, due) => {
        const date = new Date(due.paymentDateTime).getDate();
        acc[date] = (acc[date] || 0) + due.paymentAmount;
        return acc;
      }, {});
  }, [paidDues]);

  const chartData = Object.entries(dailyCollections)
    .map(([day, amount]) => ({
      day: parseInt(day),
      amount
    }))
    .sort((a, b) => a.day - b.day);

  // Collection Progress
  const collectionRate = Math.round((totalCollection / (totalCollection + totalPendingDue)) * 100) || 0;
    const handleNavigateToDuesTab = () => {
    navigate("/Rent-Due-Collection", { state: { activeTab: 1 } });
  };
  return (
    <Box sx={{ minHeight: '100vh', bgcolor: '#F5F7FA' }}>
      {/* White Top Bar */}
      <Box sx={{
        bgcolor: 'white',
        borderBottomLeftRadius: '16px',
        borderBottomRightRadius: '16px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        position: 'sticky',
        top: 0,
        zIndex: 1100,
      }}>
        {/* Header */}
        <Box sx={{ 
          px: 3, 
          pt: 2,
          pb: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <Typography variant="h6" sx={{ 
            fontWeight: 600,
            color: colors.primary 
          }}>
            Money
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {underVerificationCount > 0 && (
              <IconButton 
                onClick={() => navigate('/verification-requests')}
                sx={{ 
                  color: colors.primary,
                  position: 'relative'
                }}
              >
                <Badge 
                  badgeContent={underVerificationCount} 
                  color="error"
                  sx={{
                    '& .MuiBadge-badge': {
                      bgcolor: '#FF4B4B',
                      color: 'white',
                    }
                  }}
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            )}
            <IconButton 
              onClick={() => setSettingsOpen(true)}
              sx={{ color: colors.primary }}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        </Box>

        {/* Collection Progress */}
        <Box sx={{ px: 3, pb: 2 }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            mb: 1 
          }}>
            <Typography variant="body2" color="text.secondary">
              Collection Progress
            </Typography>
            <Typography variant="body2" sx={{ color: 'linear-gradient(-45deg, rgba(82,192,183,1) 0%, rgba(3,160,162,1) 30%, rgba(0,73,82,1) 100%)', fontWeight: 500 }}>
              {collectionRate}%
            </Typography>
          </Box>
          <LinearProgress 
            variant="determinate" 
            value={collectionRate}
            sx={{
              bgcolor: `${colors.primary}15`,
              borderRadius: 1,
              height: 6,
              '& .MuiLinearProgress-bar': {
                bgcolor: colors.primary,
                borderRadius: 1,
              }
            }}
          />
        </Box>

        {/* Tabs */}
        <Tabs
          value={tabIndex}
          onChange={(_, newValue) => setTabIndex(newValue)}
          variant="fullWidth"
          sx={{
            px: 2,
            '& .MuiTab-root': {
              fontSize: '0.875rem',
              textTransform: 'none',
              minHeight: 48,
              color: colors.primary,
              '&.Mui-selected': {
                color: colors.primary,
                fontWeight: 600
              }
            },
            '& .MuiTabs-indicator': {
              bgcolor: colors.primary,
              height: 3,
              borderRadius: '3px 3px 0 0'
            }
          }}
        >
          <Tab label="Overview" />
          <Tab label="Dues" />
          <Tab label="Collected" />
          <Tab label="Expenses" />
        </Tabs>
      </Box>

      {/* Tab Content */}
      <Container sx={{ py: 3, mb: 7 }}>
        {tabIndex === 0 ? (
          // Overview Tab
          <Box>
            <Grid container spacing={2}>
              {/* Quick Stats */}
              <Grid item xs={6}>
                <Card sx={{ 
                  borderRadius: 3,
                  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Box sx={{
                        width: 40,
                        height: 40,
                        borderRadius: 2,
                        bgcolor: `${colors.teal}15`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mr: 1
                      }}>
                        <TrendingUpIcon sx={{ color: colors.teal }} />
                      </Box>
                      <Typography variant="caption" color="text.secondary">
                        Today's Collection
                      </Typography>
                    </Box>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                      ₹{dailyCollections[new Date().getDate()]?.toLocaleString() || 0}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={6}>
                <Card sx={{ 
                  borderRadius: 3,
                  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Box sx={{
                        width: 40,
                        height: 40,
                        borderRadius: 2,
                        bgcolor: '#FFE5E5',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mr: 1
                      }}>
                        <TrendingDownIcon sx={{ color: '#FF4B4B' }} />
                      </Box>
                      <Typography variant="caption" color="text.secondary">
                        Total Pending Dues
                      </Typography>
                    </Box>
                    <Typography variant="h6" sx={{ fontWeight: 600, }}>
                    ₹{Math.floor(totalPendingDue).toLocaleString()}                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            {/* Collection Trends */}
            <Card sx={{ 
              mt: 3,
              borderRadius: 3,
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            }}>
              <CardContent>
                <Typography variant="subtitle1" sx={{ 
                  fontWeight: 600, 
                  mb: 2,
                  color: colors.primary 
                }}>
                  Collection Trends
                </Typography>
                <ResponsiveContainer width="100%" height={200}>
                  <BarChart data={chartData}>
                    <XAxis 
                      dataKey="day"
                      tickFormatter={(value) => `${value}`}
                      fontSize={12}
                    />
                    <YAxis 
                      tickFormatter={(value) => `₹${value/1000}k`}
                      fontSize={12}
                    />
                    <Tooltip 
                      formatter={(value) => `₹${value.toLocaleString()}`}
                      labelFormatter={(value) => `Day ${value}`}
                    />
                    <Bar 
                      dataKey="amount" 
                      fill={colors.teal}
                      radius={[4, 4, 0, 0]}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>

            {/* Quick Actions */}
            <Card sx={{ 
              mt: 3,
              borderRadius: 3,
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            }}>
              <CardContent>
                <Typography variant="subtitle1" sx={{ 
                  fontWeight: 600, 
                  mb: 2,
                  color: colors.primary 
                }}>
                  Quick Actions
                </Typography>
                <Grid container spacing={2}>
                  {[
                    {
                      icon: <ReceiptIcon />,
                      title: 'Record Payment',
                      onClick: () => setTabIndex(1), // This will switch to Dues tab
                      color: colors.teal
                    },
                    {
                      icon: <AccountBalanceWalletIcon />,
                      title: 'Add Expense',
                      onClick: () => navigate('/add-expense'),
                      color: '#FF4B4B'
                    }
                  ].map((action, index) => (
                    <Grid item xs={6} key={index}>
                      <Paper
                        onClick={action.onClick}
                        sx={{
                          p: 2,
                          borderRadius: 3,
                          boxShadow:0,
                          bgcolor: `${action.color}15`,
                          cursor: 'pointer',
                          transition: 'all 0.2s',
                          '&:hover': {
                            transform: 'translateY(-2px)',
                            bgcolor: `${action.color}25`,
                          }
                        }}
                      >
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          color: action.color
                        }}>
                          {action.icon}
                          <Typography variant="body2" sx={{ fontWeight: 600 ,fontSize: '0.70rem',}}>
                            {action.title}
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Box>
        ) : tabIndex === 1 ? (
          <DuesTab 
            filteredDues={aggregatedPendingDues}
            dues={dues} // Add this prop
          />
        ) : tabIndex === 2 ? (
          <Collections />
        ) : (
          <ExpensesTab />
        )}
      </Container>

     

      <BottomNavigationComponent />
    </Box>
  );
};

export default RentDueCollection;