import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Alert,
  Snackbar,
  CircularProgress,
  Card,
  CardContent,
  IconButton
} from '@mui/material';
import {
  ContentCopy as ContentCopyIcon,
  Mail as MailIcon
} from '@mui/icons-material';
import axios from 'axios';
import { BASE_URL } from '../../utils/IP';
import colors from '../../styles/colors';

const GenerateInvite = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [invitation, setInvitation] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const handleGenerateInvite = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${BASE_URL}/users/generate-homeowner-invite`,
        { email },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token
          }
        }
      );

      console.log('Response:', response.data); // Debug log

      if (response.data.success) {
        setInvitation(response.data);
        setEmail('');
        setSnackbar({ open: true, message: 'Invitation generated successfully!' });
      } else {
        throw new Error('Failed to generate invitation');
      }
    } catch (error) {
      console.error('Error details:', error.response || error);
      setError(error.response?.data?.message || 'Failed to generate invitation. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setSnackbar({ open: true, message: 'Copied to clipboard!' });
    } catch (err) {
      console.error('Copy error:', err);
      setSnackbar({ open: true, message: 'Failed to copy to clipboard' });
    }
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
      <Typography variant="h5" sx={{ mb: 3, color: colors.primary }}>
        Generate Homeowner Invitation
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Paper sx={{ p: 3, mb: 3 }}>
        <form onSubmit={handleGenerateInvite}>
          <TextField
            fullWidth
            label="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            required
            sx={{ mb: 2 }}
          />

          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            sx={{
              bgcolor: colors.teal,
              '&:hover': { bgcolor: colors.darkteal }
            }}
          >
            {loading ? <CircularProgress size={24} /> : 'Generate Invitation'}
          </Button>
        </form>
      </Paper>

      {invitation && (
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
              Invitation Generated
            </Typography>

            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" color="text.secondary">
                Email:
              </Typography>
              <Typography>{invitation.email}</Typography>
            </Box>

            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" color="text.secondary">
                Invitation Link:
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1,
                bgcolor: '#f5f5f5',
                p: 2,
                borderRadius: 1,
                mt: 1
              }}>
                <Typography sx={{ 
                  wordBreak: 'break-all',
                  fontFamily: 'monospace',
                  flex: 1
                }}>
                  {invitation.inviteLink}
                </Typography>
                <IconButton
                  onClick={() => copyToClipboard(invitation.inviteLink)}
                  size="small"
                  sx={{
                    flexShrink: 0,
                    bgcolor: colors.teal,
                    color: 'white',
                    '&:hover': {
                      bgcolor: colors.darkteal
                    }
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Box>
            </Box>

            <Box>
              <Typography variant="body2" color="text.secondary">
                Expires in:
              </Typography>
              <Typography>{invitation.expiresIn}</Typography>
            </Box>
          </CardContent>
        </Card>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Box>
  );
};

export default GenerateInvite;