import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { 
  Box, 
  Card, 
  IconButton, 
  InputAdornment, 
  TextField, 
  Typography,
  Menu,
  MenuItem,
  Chip,
  ButtonBase,
  Avatar,
  Grid,
  Container,Button
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import CallIcon from '@mui/icons-material/Call';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HomeIcon from '@mui/icons-material/Home';
import BottomNavigationComponent from '../others/BottomNavigationComponent';
import colors from '../../styles/colors';
import { selectAggregatedPendingDues } from '../../features/propertySlice';
import { ChevronRight } from '@mui/icons-material';

const TenantList = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('all');

  const { tenants, rooms } = useSelector(state => state.property);
  const aggregatedPendingDues = useSelector(selectAggregatedPendingDues); // Correct way to get the pending dues

  const filters = [
    { label: 'All Tenants', value: 'all' },
    { label: 'Active Only', value: 'active' },
    { label: 'Under Notice', value: 'notice' }
  ];

  const activeTenantCount = useMemo(() => 
    tenants.filter(t => t.status === 'Active').length,
  [tenants]);

  const onNoticeTenantCount = useMemo(() => 
    tenants.filter(t => t.status === 'OnNotice').length,
  [tenants]);

  const filteredTenants = useMemo(() => {
    return tenants.filter((tenantOccupancy) => {
      if (!tenantOccupancy?.tenant?.name) return false;
      
      const matchesSearch = tenantOccupancy.tenant.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      
      if (!matchesSearch) return false;

      switch (selectedFilter) {
        case 'active':
          return tenantOccupancy.status === 'Active';
        case 'notice':
          return tenantOccupancy.status === 'OnNotice';
        default:
          return ['Active', 'OnNotice'].includes(tenantOccupancy.status);
      }
    });
  }, [tenants, searchTerm, selectedFilter]);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterSelect = (value) => {
    setSelectedFilter(value);
    handleFilterClose();
  };

  const handleAddDue = (tenantId, roomId, event) => {
    event.stopPropagation();
    navigate('/add-due', { state: { tenantId, roomId } });
  };

  const getTenantDueAmount = (tenantId) => {
    const tenantDues = aggregatedPendingDues.find(due => 
      due.tenant && due.tenant._id === tenantId
    );
    return tenantDues ? tenantDues.totalPendingDue : 0;
  };
  // Tenant Card Component
  const TenantCard = ({ tenant, totalPendingDue }) => {
    const isUnderNotice = tenant.status === 'OnNotice';

    return (
      <Card
        onClick={() => navigate(`/tenant-profile/${tenant.tenant._id}`)}
        sx={{
          mb: 2,
          borderRadius: '16px',
          bgcolor: 'white',
          overflow: 'visible',
          position: 'relative',
          boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
          transition: 'all 0.2s',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          }
        }}
      >
        {isUnderNotice && (
          <Chip
            label="Under Notice"
            size="small"
            sx={{
              position: 'absolute',
              top: -10,
              right: 16,
              bgcolor: colors.gold,
              color: colors.primary,
              border: '2px solid white',
              fontWeight: 600,
              fontSize: '0.75rem',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}
          />
        )}

        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <Avatar
              sx={{
                width: 56,
                height: 56,
                bgcolor: isUnderNotice ? `${colors.yellow}15` : `${colors.primary}15`,
                color: isUnderNotice ? colors.primary : colors.primary,
                fontWeight: 700,
                fontSize: '1.5rem',
                border: '2px solid',
                borderColor: isUnderNotice ? `${colors.yellow}25` : `${colors.primary}25`,
              }}
            >
              {tenant.tenant.name.charAt(0)}
            </Avatar>

            <Box sx={{ flex: 1 }}>
              <Typography 
                variant="h6" 
                sx={{ 
                  fontWeight: 600,
                  fontSize: '1.1rem',
                  color: colors.primary 
                }}
              >
                {tenant.tenant.name}
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                gap: 1,
                mt: 0.5 
              }}>
                <Box
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    bgcolor: isUnderNotice ? colors.gold : '#4CAF50'
                  }}
                />
                <Typography 
                  variant="caption" 
                  color="text.secondary"
                >
                  {tenant.room.name} • {tenant.room.floor}
                </Typography>
              </Box>
            </Box>
            <IconButton sx={{ ml: 1, color: colors.primary }}>
              <ChevronRight/>
            </IconButton>
          </Box>

          <Box 
            sx={{ 
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: 2,
              mb: 2,
              pt: 0,pb:0,pl:2,pr:2,
              bgcolor: '#FFF',
              borderRadius: 2
            }}
          >
            <Box>
              <Typography 
                variant="caption" 
                color="text.secondary"
                sx={{ display: 'block', mb: 0.5 }}
              >
                Monthly Rent
              </Typography>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontWeight: 600,
                  color: colors.primary
                }}
              >
                ₹{tenant.fixedRent.toLocaleString()}
              </Typography>
            </Box>
            
            <Box>
              <Typography 
                variant="caption" 
                color="text.secondary"
                sx={{ display: 'block', mb: 0.5 }}
              >
                Pending Dues
              </Typography>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontWeight: 600,
                  color: totalPendingDue > 0 ? '#FF4B4B' : '#4CAF50'
                }}
              >
                ₹{totalPendingDue.toLocaleString()}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            gap: 1.5,
            mt: 2 
          }}>
            <ButtonBase
              onClick={(e) => handleAddDue(tenant.tenant._id, tenant.room._id, e)}
              sx={{
                flex: '1',
                py: 1,
                px: 2,
                bgcolor: colors.primary,
                color: 'white',
                borderRadius: '12px',
                fontSize: '0.875rem',
                fontWeight: 600,
                transition: 'all 0.2s',
                '&:hover': {
                  bgcolor: colors.error,
                  transform: 'translateY(-1px)'
                }
              }}
            >
              <AddIcon sx={{ mr: 1, fontSize: 18 }} />
              Add Due
            </ButtonBase>

            <Box sx={{ 
              display: 'flex', 
              gap: 1 
            }}>
              {['phone', 'whatsapp'].map((type) => (
                <IconButton
                  key={type}
                  component="a"
                  href={type === 'phone' ? 
                    `tel:${tenant.tenant.phone}` : 
                    `https://wa.me/${tenant.tenant.phone}`
                  }
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    bgcolor: `${colors.primary}10`,
                    color: colors.primary,
                    width: 40,
                    height: 40,
                    '&:hover': {
                      bgcolor: `${colors.primary}20`,
                    }
                  }}
                >
                  {type === 'phone' ? <CallIcon /> : <WhatsAppIcon />}
                </IconButton>
              ))}
            </Box>
          </Box>
        </Box>
            
      </Card>
    );
  };

  return (
    <Box sx={{ pb: 8, bgcolor: '#F5F7FA', minHeight: '100vh' }}>
      {/* Header */}
      <Box sx={{
        bgcolor: 'white',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        position: 'sticky',
        top: 0,
        zIndex: 1100,
      }}>
        <Container disableGutters sx={{ px: 3 }}>
          <Box sx={{ pt: 2, pb: 1 }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center' 
            }}>
              <Typography variant="h6" sx={{ fontWeight: 600, color: colors.primary }}>
                Tenants
              </Typography>
              <IconButton onClick={() => navigate('/add-tenant')}>
                <PersonAddIcon sx={{ color: colors.primary }} />
              </IconButton>
            </Box>

            {/* Quick Stats */}
          <Grid container sx={{ mt: 0,mt:3,mb:1, ml: 2, color: colors.primary, fontWeight: 'medium', }}>
            <Grid item xs={4} >
              <Typography variant="body1">Total Tenant:</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1" sx={{ color: colors.primary, fontWeight: 'bold'}}>{tenants.length}</Typography>
            </Grid>
            <Grid item xs={4} >
              <Typography variant="body1">Under Notice:</Typography>
            </Grid>
            <Grid item xs={2}>
            <Typography variant="body1" sx={{ color: colors.primary, fontWeight: 'bold'}}>{onNoticeTenantCount}</Typography>
            </Grid>     
          </Grid>

            <Box sx={{ 
              mt: 3, 
              mb: 2, 
              display: 'flex', 
              gap: 1.5, 
              alignItems: 'center'
            }}>
              <TextField
                fullWidth
                placeholder="Search tenants..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: colors.primary, fontSize: 20 }} />
                    </InputAdornment>
                  ),
                  sx: {
                    height: 40,
                    padding: '0 12px',
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    bgcolor: 'white',
                    borderRadius: 2,
                    '& fieldset': {
                      borderColor: 'rgba(0, 0, 0, 0.1)',
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgba(0, 0, 0, 0.2)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.primary,
                    }
                  },
                  '& .MuiInputBase-input': {
                    padding: '8px 0',
                    fontSize: '0.875rem',
                  }
                }}
              />
              <IconButton 
                onClick={handleFilterClick}
                sx={{ 
                  bgcolor: filterAnchorEl ? colors.primary : 'white',
                  width: 40,
                  height: 40,
                  borderRadius: '50%',
                  padding: 0,
                  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                  '&:hover': { 
                    bgcolor: filterAnchorEl ? colors.primary : 'white' 
                  }
                }}
              >
                <FilterListIcon 
                  sx={{ 
                    color: filterAnchorEl ? 'white' : colors.primary,
                    fontSize: 20
                  }} 
                />
              </IconButton>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Active Filter Chip */}
      <Container disableGutters sx={{ px: 3 }}>
        {selectedFilter !== 'all' && (
          <Box sx={{ mt: 2 }}>
            <Chip
              label={filters.find(f => f.value === selectedFilter)?.label}
              onDelete={() => setSelectedFilter('all')}
              color="primary"
              sx={{ 
                bgcolor: `${colors.primary}15`,
                color: colors.primary,
                '& .MuiChip-deleteIcon': {
                  color: colors.primary
                }
              }}
            />
          </Box>
        )}

        {/* Tenants List */}
        <Box sx={{ py: 2 }}>
          {/* Continuing from the previous code... */}
          {filteredTenants.map((tenant) => (
            <TenantCard
              key={tenant._id}
              tenant={tenant}
              totalPendingDue={getTenantDueAmount(tenant.tenant._id)}
            />
          ))}

          {filteredTenants.length === 0 && (
            <Box sx={{ 
              textAlign: 'center', 
              mt: 8,
              p: 3,
              bgcolor: 'white',
              borderRadius: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2
            }}>
              <HomeIcon 
                sx={{ 
                  fontSize: 48, 
                  color: colors.primary,
                  opacity: 0.5 
                }} 
              />
              <Typography color="text.secondary" variant="body1">
                {searchTerm ? 
                  'No tenants found matching your search' : 
                  'No tenants found'
                }
              </Typography>
              <Button
                variant="outlined"
                onClick={() => navigate('/add-tenant')}
                sx={{
                  color: colors.primary,
                  borderColor: colors.primary,
                  '&:hover': {
                    borderColor: colors.darkteal,
                    bgcolor: `${colors.primary}10`
                  }
                }}
              >
                Add New Tenant
              </Button>
            </Box>
          )}
        </Box>
      </Container>

      {/* Filter Menu */}
      <Menu
        anchorEl={filterAnchorEl}
        open={Boolean(filterAnchorEl)}
        onClose={handleFilterClose}
        PaperProps={{
          elevation: 3,
          sx: {
            mt: 1.5,
            minWidth: 180,
            borderRadius: 2,
            bgcolor: 'white',
            '& .MuiMenuItem-root': {
              px: 2,
              py: 1.5,
              '&.Mui-selected': {
                bgcolor: `${colors.primary}15`,
                color: colors.primary,
                '&:hover': {
                  bgcolor: `${colors.primary}25`,
                }
              },
              '&:hover': {
                bgcolor: `${colors.primary}10`,
              }
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {filters.map((filter) => (
          <MenuItem
            key={filter.value}
            onClick={() => handleFilterSelect(filter.value)}
            selected={selectedFilter === filter.value}
            sx={{
              borderRadius: 1,
              mx: 1,
              my: 0.5,
            }}
          >
            <Typography variant="body2">
              {filter.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>

      {/* Floating Add Button */}
      <Box
        onClick={() => navigate('/add-tenant')}
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 80,
          zIndex: 1000,
          bgcolor: colors.teal,
          color: 'white',
          borderRadius: '12px',
          py: 1,
          px: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
          cursor: 'pointer',
          transition: 'all 0.2s',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 6px 16px rgba(0,0,0,0.2)',
          },
        }}
      >
        <PersonAddIcon sx={{ fontSize: 20 }} />
        <Typography sx={{ 
          fontSize: '0.875rem',
          fontWeight: 500,
          whiteSpace: 'nowrap'
        }}>
          Add Tenant
        </Typography>
      </Box>

      <BottomNavigationComponent />
    </Box>
  );
};

export default TenantList;